

import React, { useEffect, useState } from "react";
import axios from "axios";
import "../style/login.css";
import logoIman from "../img/logos/logo_iman_full_white_red.png";
import welcomeMsg from "../img/login/welcomeMessageWhite.png";
import welcomeMsgPhone from "../img/login/welcomeMessageWhitePhone.png";
import profile from "../img/login/profileIconBlue.png";
import profile2 from "../img/login/profileIconWhite.png";
import formMessage from "../img/login/detailsMessageBlue.png";
import formMessageWhite from "../img/login/detailsMessageWhite.png";
import { useMediaQuery } from 'react-responsive';
import Cookies from 'js-cookie';
import ForgotPwd from "./forgot_pwd";


const Login = (props) => {
    const param = props.param;
    const version = require("../version.json");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const isTabMob = useMediaQuery({ maxWidth: "800px" });
    const [lastLoggedInUser, setLastLoggedInUser] = useState(null);
    const [isImageValid, setIsImageValid] = useState(true);

    const handleImageError = () => {
        setIsImageValid(false);
    };


    document.title = param.webApp + " " + version[0].build;

    const handlerSubmit = async (e) => {
        e.preventDefault();
        //Recuperation des donnes du formulaire
        // const email=e.target.email.value;
        // const password=e.target.password.value;
        //appelle du fichier jason qui contient url  de service
        var Gfunc = require('../Gfunc');
        //envoie des donnees vers le service
        await axios({
            method: 'POST',
            url: param.urlService + 'auth.php',
            headers: { 'content-type': 'application/json', 'appID': param.appID },
            data: JSON.stringify({ login: email, password: password })
        })
            //recuperation des donnees du service pour l'autorisation d'acces ou non
            .then(response => {
                if (response.data.err) {
                    alert(response.data.err)
                } else {
                    Gfunc.axiosResponse(response, () => {
                        localStorage.setItem('build', version[0].build);
                        localStorage.setItem('token', response.data.jData.token);
                        localStorage.setItem('isLoged', true);
                        localStorage.setItem('name', response.data.jData.name);
                        localStorage.setItem('nickname', response.data.jData.nickname);
                        localStorage.setItem('uid', response.data.jData.uid);
                        localStorage.setItem('param', JSON.stringify({ 'urlService': param.urlService, 'appID': param.appID }));
                        Cookies.set('lastLoggedInUser', JSON.stringify({ id: response.data.jData.uid, name: response.data.jData.name, login: email }), { expires: 7 });
                        window.location = ('/');
                    });
                }
            })
            .catch(error => {
                alert(error);
                setEmail('');
                setPassword('');
            })
    }

    useEffect(() => {
        const storedUser = Cookies.get('lastLoggedInUser');
        if (storedUser) {
            const e = JSON.parse(storedUser);
            setLastLoggedInUser(e);
            setEmail(e.login);
        }
    }, []);

    return (
        <div className={(!isTabMob) ? "login-page" : "login-page2"}>
            {(!isTabMob) ? <div className="login-card">
                <div className="welcomePart">
                    <div id="intro">
                        <div id="logoIman" >
                            <img src={logoIman} alt="iMan" />
                        </div>
                        <div id="welcomeMsg" >
                            <img src={welcomeMsg} alt="Message" />
                        </div>
                    </div>
                    <div className="logos-grid">
                        <div id="logosDiv">
                            <div id="startechDiv" />
                            <div id="digitartsDiv" />
                            <div id="partnerDiv" />
                            <div id="partnerArDiv" />
                        </div>
                    </div>
                </div>
                <div className="formLogin">
                    <div className="webProfile">
                        {
                            lastLoggedInUser ? <div id="userPictureCont">
                                {(isImageValid) && <img id="userPicture" src={param.urlimg + "user/" + lastLoggedInUser.id + ".jpg"} alt="Profile" onError={handleImageError} />
                                }
                            </div> : <div></div>
                        }
                        <div id="profile" >
                            <img src={profile} alt="Profile" />
                            <p id="userName">{(lastLoggedInUser) ? lastLoggedInUser.name : "COMPTE"}</p>
                        </div>
                    </div>
                    <div id="formMessage" >
                        <img src={formMessage} alt="Message" />
                    </div>
                    <div>
                        <div id="form" >

                            <form id="formLogin" onSubmit={handlerSubmit}>
                                <div id="emailDiv"><input type="text" id="email" name="email" value={email} onChange={e => setEmail(e.target.value)} /></div>
                                <div id="passDiv"><input type="password" id="password" name="password" value={password} onChange={e => setPassword(e.target.value)} required /></div>
                        </form>
                            <ForgotPwd param={param} email={email} />
                            <div id="buttonDiv" ><button id='subo' form="formLogin" type="submit" /></div>
                        </div>

                    </div>
                    <div id="authBottom">
                        <span id="copyr">{param.webApp} {version[0].build} &copy; {param.copyright}</span>
                    </div>
                </div>
            </div> :
                <div className="tabMobPart">
                    <div id="intro">
                        <div id="logoIman" >
                            <img src={logoIman} alt="iMan" />
                        </div>
                        <div id="welcomeMsg" >
                            <img src={welcomeMsgPhone} alt="Message" />
                        </div>
                    </div>
                    <div className="mobileProfile">
                        {
                            lastLoggedInUser ? <div id="userPictureCont">
                                {(isImageValid) && <img id="userPicture" src={param.urlimg + "user/" + lastLoggedInUser.id + ".jpg"} alt="Profile" onError={handleImageError} />
                                    //<img id="userPicture" src={"https://labo.digitarts.cloud/img/iman/user/49.jpg"} alt="Profile" />
                                }
                            </div> : <div></div>
                        }
                        <div id="profile" >
                            <img src={profile2} alt="Profile" />
                            <p id="userName">{(lastLoggedInUser) ? lastLoggedInUser.name : "COMPTE"}</p>
                        </div>
                        <div id="formMessage" >
                            <img src={formMessageWhite} alt="Message" />
                        </div>
                    </div>
                    <div className="formLogin2">
                        <div id="form2" >
                            <form id="formLogin2" onSubmit={handlerSubmit}>
                                <div id="emailDiv"><input type="text" id="email" name="email" value={email} onChange={e => setEmail(e.target.value)} /></div>
                                <div id="passDiv"><input type="password" id="password" name="password" value={password} onChange={e => setPassword(e.target.value)} required /></div>
                            </form>
                            <ForgotPwd />
                            <div id="buttonDiv" ><button id='subo' form="formLogin2" type="submit" /></div>
                        </div>
                    </div>
                    <div className="logos-grid">
                        <div id="logosDiv">
                            <div id="startechDiv" />
                            <div id="digitartsDiv" />
                            <div id="partnerDiv" />
                            <div id="partnerArDiv" />
                        </div>
                    </div>
                    <div id="authBottom">
                        <span id="copyr">{param.webApp} {version[0].build} &copy; {param.copyright}</span>
                    </div>
                </div>
            }
        </div>
    );
};

export default Login;
