import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";

import Icon from "@mdi/react";
import {
  ClickAwayListener,
  ListItemIcon,
  MenuItem,
  Tooltip,
} from "@mui/material";
import "../style/menu.css";
import { useAlert } from "../contexts/alertContext";
import AvatarCell from "../components/avatar";
import UserProfile from "../components/UserProfile";
import CustomIconButton from "../components/menu_button";
import ResetPwd from "./changepass";
import axios from "axios";
import VerticalScrollableTabs from "../components/vertical_scrollabl_items";
import { ReactComponent as Iconp } from "../img/icons/param.svg";

const MainMenu = React.memo(({ param, ...props }) => {
  var index = props.menu.findIndex((obj) => obj.level === 1);
  if (index < 0) index = 0;

  const [showSubMenu, setShowSubMenu] = useState(
    window.location.pathname !== "/"
      ? window.location.pathname.split("/")[1]
      : props.menu[index].id ?? ""
  );

  const { openMenu, handleOpenMenu } = useAlert();
  const mdi = require("@mdi/js");
  const Gfunc = require("../Gfunc");
  const history = useHistory();



  const handleNavigate = (item, child) => {
    const currentPath = window.location.pathname; // Get current path
    const newPath = `/${item.id}/${child.id}`; // Define new path

    if (currentPath === newPath) {
      history.replace("/temp"); // Temporary navigation to trigger a reset
      setTimeout(() => history.replace(newPath), 0); // Navigate back to the desired path
    } else {
      history.push(newPath); // Navigate normally if it's a new path
    }
  };


  // Memoized Logout function
  const Logout = useMemo(
    () => () => {
      axios
        .get(`${param.urlService}disconnect.php`, {
          headers: Gfunc.getHeader(),
        })
        .then((response) => {
          if (response.data.jData === true) {
            localStorage.removeItem("token");
            localStorage.setItem("isLoged", false);
            localStorage.clear();
            window.location = "/";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [param, Gfunc]
  );

  // Memoized menuItems for rendering performance
  const memoizedMenuItems = useMemo(() => {
    return [
      <ResetPwd param={param} />,
      { divider: true },
      <MenuItem onClick={Logout}>
        <ListItemIcon>
          <Icon path={mdi.mdiLogout} color="grey" size="22px" />
        </ListItemIcon>
        Déconnexion
      </MenuItem>,
    ];
  }, [param, Logout]);

  const handleClick = (val) => {
    if (val !== showSubMenu) {
      setShowSubMenu(val);
    }
  };

  const DynamicIcon = ({ iconName }) => {
    if (typeof iconName === "string")
      return <Icon path={mdi[iconName]} size="22px" color="white" />;
    else return iconName;
  };

  const HandleResponsive = () => {
    handleOpenMenu(!openMenu);
    const div = document.getElementById("resizable-div");
    div.classList.toggle("sidebar");
    div.classList.toggle("sidebarResp");
  };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return windowSize;
  }

  const size = useWindowSize();

  // Memoize the menu.map to prevent unnecessary re-renders
  const memoizedMenu = useMemo(() => {
    return props.menu.map((item, index) => (
      <div key={"nav" + index} className="nav-item">
        <NavLink
          aria-current="page"
          to={`/${item.id}`}
          id={item.id}
          onClick={() => handleClick(item.id)}
        >
          {showSubMenu === item.id ? (
            <Icon path={mdi.mdiMenuDown} size="18px" />
          ) : (
            <Icon path={mdi.mdiMenuRight} size="18px" />
          )}
          <DynamicIcon iconName={item.icon} />
          {openMenu && <span> {item.parent}</span>}
        </NavLink>
        {showSubMenu === item.id && (
          <div className={"sub-nav-group"}>
            {item.children.map((child, childIndex) => (
              <Tooltip
                key={"wrapper" + childIndex}
                title={!openMenu ? child.child : ""}
                arrow
                disableInteractive
                placement="right"
              >
                <div className="sub-nav-item-wrapper">
                  <NavLink
                    key={childIndex}
                    to={`/${item.id}/${child.id}`}
                    className="sub-nav-item"
                    activeClassName="sub-nav-item-selected"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      handleNavigate(item, child); // Call the updated navigation handler
                    }}
                  >
                    <DynamicIcon iconName={child.icon} />
                    {openMenu && <span>{child.child}</span>}
                  </NavLink>

                </div>
              </Tooltip>
            ))}
          </div>
        )}
      </div>
    ));
  }, [props.menu, openMenu, showSubMenu, mdi]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (size.width < 420) handleOpenMenu(false);
      }}
    >
      <div id="resizable-div" className={openMenu ? "sidebar" : "sidebarResp"}>
        {openMenu ? (

          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
            <CustomIconButton
              menuItems={memoizedMenuItems}
              tooltipTitle="Paramètres du compte"
              avatarText="M"
              buttonContent=""
              icon={<Iconp style={{ height: "20px", width: "20px" }} fill="white" />}
              right={38}
            />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
            <CustomIconButton
              menuItems={memoizedMenuItems}
              tooltipTitle="Paramètres du compte"
              avatarText="M"
              buttonContent=""
              icon={<Iconp style={{ height: "10px", width: "10px" }} fill="white" />}
              right={177}
            />
          </div>
        )}
        <div
          id={openMenu ? "head-men" : "head-men2"}
          onClick={HandleResponsive}
        >

          {openMenu ? (
            <>
              <div>
                <AvatarCell
                  id={localStorage.getItem("uid")}
                  imgUrl={param.urlimg + "user/"}
                  ext={"jpg"}
                  width="70px"
                  height="70px"
                />
              </div>
            </>
          ) : (
            <Tooltip
              title={<UserProfile />}
              disableInteractive
              placement="right"
              arrow
            >
              <div>
                <AvatarCell
                  id={localStorage.getItem("uid")}
                  imgUrl={param.urlimg + "user/"}
                  ext={"jpg"}
                />
              </div>
            </Tooltip>
          )}
        </div>

        {openMenu && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              position: "relative",
            }}
          >
            <UserProfile />
            {/* <div style={{ position: "absolute", right: "20px", top: "0" }}>
              <CustomIconButton
                menuItems={memoizedMenuItems}
                tooltipTitle="Paramètres du compte"
                avatarText="M"
                buttonContent=""
                icon={<Icon path={mdiCog} size={"20px"} color={"white"} />}
              />
            </div> */}
          </div>
        )}

        <div className={openMenu ? "nav-group" : "nav-group-resp"}>
          <VerticalScrollableTabs>{memoizedMenu}</VerticalScrollableTabs>
        </div>
      </div>
    </ClickAwayListener>
  );
});

export default MainMenu;
