import React from "react";
import {
    Card,
    CardContent,
} from "@mui/material";
import "../style/components.css";
import { ReactComponent as NotAllowed } from "../img/icons/accesinterdit.svg";
export const ErrorBoundaryMessage = (props) => {
    return (
        <Card style={{ marginTop: "50px" }}>
            <CardContent className="notAllowed">
                <div style={{ width: "100%", height: "40%" }}>
                    <NotAllowed width={"50%"} height={"100%"} fill="#D0D7E4" />
                </div>

                <div className="message">Quelque chose s'est mal passé</div>

            </CardContent>
        </Card>
    );
};