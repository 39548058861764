import React from 'react';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Met à jour l'état pour afficher le fallback UI
    // console.log("didcatch")
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Log l'erreur pour du debugging
    console.error("Erreur capturée par ErrorBoundary :", error);
    console.error("Stack de composants :", info.componentStack);

    // Optionally log to an external service
    // if (process.env.NODE_ENV === 'production') {
    //     axios({
    //         method: "GET",
    //         url: param.urlservice+"_sendmail.php?do=send",
    //         headers: getHeader(),
    //     }).catch(function (error) {
    //         error["data"] = "";
    //         return error;
    //     });
    // }
  }

  render() {
    if (this.state.hasError) {
      // Affiche une UI de secours ou continue le rendu sans afficher de fallback
      return this.props.fallback || null; // Affiche null si aucun fallback fourni
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
